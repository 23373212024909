import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import { navigate, Link } from "gatsby";
import * as styles from "./about-us.module.css";
import Layout from "../components/Base/Layout";

const AboutUs: FunctionComponent = () => {
  const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);

  const onButton1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onButton3Click = useCallback(() => {
    navigate("/sign-in-page");
  }, []);

  const openNavigationMobile = useCallback(() => {
    setNavigationMobileOpen(true);
  }, []);

  const closeNavigationMobile = useCallback(() => {
    setNavigationMobileOpen(false);
  }, []);

  const onPrivacyPolicyClick = useCallback(() => {
    navigate("/privacy-policy");
  }, []);

  const onShippingPolicyClick = useCallback(() => {
    navigate("/shipping-policy");
  }, []);

  const onTermsAndConditionsClick = useCallback(() => {
    navigate("/terms-conditions");
  }, []);

  return (
    <Layout>
      <div className={styles.aboutUs}>
        <div className={styles.aboutUsIntro}>
          <div className={styles.aboutUs1}>
            <div className={styles.aboutUsTop}>
              <div className={styles.aboutUsText}>
                <div className={styles.title}>
                  <div className={styles.display}>About Us</div>
                </div>
                <div className={styles.heroText}>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      The Canadian Supply is a one-stop shop for health and wellness
                      products and clinic instruments.
                    </div>
                  </div>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      Our team members, with a combined experience of 800,000
                      labour hours in the healthcare industry, came together
                      with a shared mission of leading the medical wholesale
                      sector by offering affordable products and clinical
                      instruments for medical professionals, hospitals and
                      clinics.
                    </div>
                  </div>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      We identified some significant gaps in this industry
                      before incorporating The Canadian Supply:
                    </div>
                  </div>
                </div>
              </div>
              <img className={styles.imageIcon} alt="" src="/image@2x.png" />
            </div>
            <div className={styles.features}>
              <div className={styles.featuresRow}>
                <div className={styles.feature}>
                  <div className={styles.title1}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled8.svg"
                    />
                    <b className={styles.title2}>1</b>
                  </div>
                  <div className={styles.description}>
                    Affordability and quality do not go hand in hand
                  </div>
                </div>
                <div className={styles.feature}>
                  <div className={styles.title1}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled9.svg"
                    />
                    <b className={styles.title2}>2</b>
                  </div>
                  <div className={styles.description}>
                    There is no one-stop shop that sells all clinical products
                    under one roof
                  </div>
                </div>
                <div className={styles.feature}>
                  <div className={styles.title1}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled10.svg"
                    />
                    <b className={styles.title2}>3</b>
                  </div>
                  <div className={styles.description}>
                    Very few online businesses cater to this B2B niche
                  </div>
                </div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The Canadian Supply was incorporated with the vision to meet these
                  three gaps. Our broad range of industry knowledge and
                  professional network make us perfect consultant when you think
                  about starting or upgrading your clinic.
                </div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>
                  If you have the vision to serve the community, then we have a
                  solution to make it easy for you. With an inventory of leading
                  brands' products, we ensure you get the best from us.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.aboutUsSection}>
          <div className={styles.aboutUsFeatures}>
            <div className={styles.title}>
              <b className={styles.label}>What We Offer</b>
            </div>
            <div className={styles.grid2x2}>
              <div className={styles.row1}>
                <div className={styles.feature3}>
                  <img
                    className={styles.iconFilled5}
                    alt=""
                    src="/icon--filled11.svg"
                  />
                  <div className={styles.text}>
                    <div className={styles.title7}>
                      Everything you need to start or upgrade a medical business
                    </div>
                  </div>
                </div>
                <div className={styles.feature3}>
                  <img
                    className={styles.iconFilled5}
                    alt=""
                    src="/icon--filled11.svg"
                  />
                  <div className={styles.text}>
                    <div className={styles.title7}>Product consultation</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.aboutUsFeatures1}>
            <div className={styles.cardLabel1}>
              <b className={styles.label}>Why Us</b>
            </div>
            <div className={styles.grid2x21}>
              <div className={styles.row11}>
                <div className={styles.feature5}>
                  <img
                    className={styles.iconFilled5}
                    alt=""
                    src="/icon--filled11.svg"
                  />
                  <div className={styles.text}>
                    <div className={styles.title7}>Competitive prices</div>
                  </div>
                </div>
                <div className={styles.feature5}>
                  <img
                    className={styles.iconFilled5}
                    alt=""
                    src="/icon--filled14.svg"
                  />
                  <div className={styles.text}>
                    <div className={styles.title7}>Great range of products</div>
                  </div>
                </div>
                <div className={styles.feature5}>
                  <img
                    className={styles.iconFilled5}
                    alt=""
                    src="/icon--filled15.svg"
                  />
                  <div className={styles.text}>
                    <div className={styles.title7}>
                      Easy and Affordable Global Shipping
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.grid2x22}>
              <div className={styles.feature5}>
                <img
                  className={styles.iconFilled5}
                  alt=""
                  src="/icon--filled16.svg"
                />
                <div className={styles.text}>
                  <div className={styles.title7}>Reliable Brands</div>
                </div>
              </div>
              <div className={styles.row12}>
                <div className={styles.feature9}>
                  <img
                    className={styles.iconFilled5}
                    alt=""
                    src="/icon--filled17.svg"
                  />
                  <div className={styles.text}>
                    <div className={styles.title7}>24 x 7 customer support</div>
                  </div>
                </div>
              </div>
              <div className={styles.row2}>
                <div className={styles.feature9}>
                  <img className={styles.iconFilled5} alt="" />
                  <div className={styles.text}>
                    <div className={styles.title7}>24 x 7 customer support</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
